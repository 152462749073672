import React from "react";
import "./Home.css" // Import CSS file for additional styling
import HeroImage from "../assets/pic.jpeg";
import backgroundImage from "../assets/john-towner-3Kv48NS4WUU-unsplash.jpg"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";

const Home = () => {
  const resumeLink = "/Resume_Ajit_Patil.pdf1";  // Replace with the actual path to your resume

  return (
    <div
    id="home"
    className="w-full relative h-screen bg-cover blur-effect mist-effect:"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    {/* Particle Elements */}
    <div className="particle pollen"></div>
      <div className="particle firefly"></div>
      <div className=" max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row mt-0">
        <div className="flex flex-col justify-center h-full text-center md:text-left md:items-start">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            I'm a Full Stack Developer
          </h2>
          <p className="text-white py-4 max-w-md">
            As a seasoned Software Developer, I excel in crafting and implementing innovative software solutions, demonstrating proficiency in both front-end and back-end development to deliver seamless and efficient applications.
          </p>

          <div className="flex items-center mt-4">
            <Link
              to="portfolio"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Portfolio
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>

            {/* Add Resume Download Link */}
            <a
              href={resumeLink}
              downloadtarget="_blank" 
              className="group text-white w-fit px-6 py-3 ml-4 flex items-center rounded-md bg-gradient-to-r from-yellow-500 to-orange-500 cursor-pointer"
            >
              Resume
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </a>
          </div>
        </div>

        <div>
          <img
            src={HeroImage}
            alt="my profile"
            className="rounded-full mx-auto w-1/2 md:w-2/3"
          />
        </div>
      </div>

      <div className="absolute bottom-14 left-0 right-0 text-center text-gray-300">
        <span className="bg-white-800 px-2 py-1 inline-block animate-pulse relative text-sm">
          This website is a Live CI/CD project hosted on AWS S3 with Content Distribution utilizing AWS CloudFront, seamlessly integrated with GitHub Actions
        </span>
      </div>
    </div>
  );
};

export default Home;
